import { type FC, type PropsWithChildren } from 'react';
import { Helmet } from 'react-helmet';
import { usePageTracking } from 'utils/ga';
import PublicLayout from 'components/templates/PublicLayout';

const PasswordReset: FC<PropsWithChildren> = () => {
  usePageTracking(); // ページビュー計測

  return (
    <PublicLayout>
      <Helmet>
        <title>パスワードリセット: サイト分析、レポートはGrowth Support</title>
      </Helmet>
      PasswordReset
    </PublicLayout>
  );
};

export default PasswordReset;
